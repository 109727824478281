<script setup lang="ts">
import {defineProps, ref} from "vue";
import {IProps} from "@/features/ProfileMenu/types/ui/profile-menu";
import {useStore} from "vuex";
import {MutationsTypes} from "@/shared/types/store-modules/user";
import exit from "@/features/ProfileMenu/api/exit";

// define store
const store = useStore();

// define props
defineProps<IProps>();

// define properties
const rootElement = ref<HTMLDivElement | null>(null);

const removeToken = () => {
  localStorage.removeItem('access_token');
  store.commit(`user/${MutationsTypes.SET_ACCESS_TOKEN}`);
};
</script>

<template>
  <div ref="rootElement"
       class="bg-black-green-200 px-2 py-2.5 text-base rounded-md
       text-white font-semibold min-w-52 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]"
  >
    <div class="flex flex-row items-center space-x-2 px-1">
      <img src="../assets/profile-user.png"
           class="w-6 h-6"
           alt="Профиль"
           title="Профиль"
      >
      <span>{{ login }}</span>
    </div>
    <hr class="h-[1.5px] bg-blue-purple border-0 my-2">
    <ul class="flex flex-col">
      <li class="flex flex-row items-center space-x-2 hover:bg-bluish-black
                 rounded-lg py-1 px-1.5 cursor-pointer duration-100">
        <img src="../assets/settings.png"
             class="w-6 h-6"
             alt="Настройки"
             title="Настройки"
        >
        <span>Настройки</span>
      </li>
      <li class="flex flex-row items-center space-x-2 hover:bg-bluish-black
                 rounded-lg py-1 px-1.5 cursor-pointer duration-100">
        <img src="../assets/like.png"
             class="w-6 h-6"
             alt="Избранное"
             title="Избранное"
        >
        <span>Избранное</span>
      </li>
      <li class="flex flex-row items-center space-x-2 hover:bg-bluish-black
                 rounded-lg py-1 px-1.5 cursor-pointer duration-100"
          @click="exit(store, removeToken)"
      >
        <img src="../assets/exit.png"
             class="w-6 h-6"
             alt="Выйти"
             title="Выйти"
        >
        <span>Выйти</span>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>